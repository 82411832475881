import { useStoredState } from '@utils/useStoredState';
import React, { FC, PropsWithChildren, createContext, useContext } from 'react';

export interface AnchorContext {
	bounds: {
		min: number;
		max: number;
	};
	value: {
		min: number;
		max: number;
	};
	onChange: (value: { min: number; max: number }) => void;
}

const bounds = {
	min: 20210,
	max: 20242,
};

const Context = createContext<AnchorContext>({
	bounds,
	value: { min: 20240, max: 20242 },
	onChange: () => {},
});

const AnchorProvider: FC<PropsWithChildren> = ({ children }) => {
	const [value, onChange] = useStoredState('anchor', {
		min: 20240,
		max: 20242,
	});
	return (
		<Context.Provider value={{ value, onChange, bounds }}>
			{children}
		</Context.Provider>
	);
};

export default AnchorProvider;

export const useAnchorContext = () => useContext(Context);
