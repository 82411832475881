import React, { FC } from 'react';
import { DashboardScope } from '../../useDashboardScope';
import {
	Box,
	Button,
	Divider,
	Typography,
	useTheme,
	useColorScheme,
} from '@mui/joy';
import DebounceSlider from '@components/Input/DebounceSlider';
import { MdAdd } from 'react-icons/md';
import Filter from '../Filter';

export interface FooterProps {
	scope: DashboardScope;
	setScope: (scope: DashboardScope) => void;
}

export const anchorOptions = [
	20210, 20211, 20212, 20220, 20221, 20222, 20230, 20231, 20232, 20240, 20241,
	20242,
];

const Footer: FC<FooterProps> = ({ scope, setScope }) => {
	const { palette } = useTheme();
	const { colorScheme } = useColorScheme();

	return (
		<Box
			sx={{
				paddingBottom: 1,
				paddingRight: 1,
				paddingLeft: 1,
			}}
		>
			<Box
				sx={{
					border:
						colorScheme === 'dark' ? 'none' : `1px solid ${palette.divider}`,
					background: palette.background.surface,
					borderRadius: '8px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							gap: 1,
							paddingLeft: 2,
							paddingRight: 2,
						}}
					>
						<Filter
							value={scope.attributes}
							onChange={(v) => {
								setScope({
									...scope,
									attributes: v,
								});
							}}
						>
							{({ loading, error, onClick, suggestedLabel }) => (
								<Button
									// onClick={() => {
									// 	if (attributeOptions.length > 0)
									// 		setFocus({
									// 			key: attributeOptions[0].key,
									// 			values: [attributeOptions[0].values[0]],
									// 		});
									// }}

									onClick={onClick}
									disabled={Boolean(error)}
									loading={loading}
									variant={scope.attributes.length > 0 ? 'solid' : 'soft'}
								>
									{suggestedLabel}
								</Button>
							)}
						</Filter>
						{/* {scope.attributes.map((a, i) => (
							<Chip
								onClick={() => {
									setFocus(a);
								}}
								key={`att_${i}`}
								color="primary"
								variant="solid"
								sx={{
									paddingLeft: 2,
									paddingRight: 2,
								}}
								endDecorator={
									<Box
										sx={{
											paddingLeft: 1,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<ChipDelete
											onClick={() => {
												const newAttributes = [...scope.attributes];
												newAttributes.splice(i, 1);
												setScope({
													...scope,
													attributes: newAttributes,
												});
											}}
										/>
									</Box>
								}
							>
								{splitByCapital(capFirst(a.key))}: {niceJoin(a.values)}
							</Chip>
						))} */}
					</Box>
					<Divider orientation="vertical" />
					<Box
						sx={{
							flex: 1,
							paddingLeft: 6,
							paddingRight: 6,
							paddingBottom: 4.5,
							paddingTop: 0.5,
						}}
					>
						<DebounceSlider
							size="sm"
							color="primary"
							sx={{ p: 0 }}
							value={[
								anchorOptions.indexOf(scope.anchors.start),
								anchorOptions.indexOf(scope.anchors.end),
							]}
							onChange={(v) => {
								if (Array.isArray(v)) {
									setScope({
										...scope,
										anchors: {
											start: anchorOptions[v[0]],
											end: anchorOptions[v[1]],
										},
									});
								}
							}}
							min={0}
							max={anchorOptions.length - 1}
							marks={anchorOptions.map((o, i) => {
								const year = Math.floor(o / 10);
								const semesters = ['Spr', 'Sum', 'Fall'];
								const semester = semesters[o % 10];
								return {
									value: i,
									label: (
										<Box>
											<Typography level="body-xs">
												{semester} {year}
											</Typography>
										</Box>
									),
								};
							})}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Footer;
