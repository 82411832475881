import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../../scenes/Dashboard';
import DashboardRoot from './components/DashboardRoot';

const DashboardWrap: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="20240" replace={true} />} />
			<Route
				path=":anchorStart"
				element={<Navigate to="20242" replace={true} />}
			/>
			<Route path=":anchorStart/:anchorEnd" element={<DashboardRoot />} />
			<Route path=":anchorStart/:anchorEnd/:u1/*" element={<Dashboard />} />
			<Route path=":anchorStart/:anchorEnd/:u1/:u2/*" element={<Dashboard />} />
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/:u6/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/:u6/:u7/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/:u6/:u7/:u8/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/:u6/:u7/:u8/:u9/*"
				element={<Dashboard />}
			/>
			<Route
				path=":anchorStart/:anchorEnd/:u1/:u2/:u3/:u4/:u5/:u6/:u7/:u8/:u9/:u10"
				element={<Dashboard />}
			/>
		</Routes>
	);
};

export default DashboardWrap;
